import * as React from 'react';
import MuiButton from '@mui/material/Button';
import { ThemeProvider } from '@mui/material/styles';
import { colorTheme } from '../const';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import {HashLink} from 'react-router-hash-link';

export default function Button(props) {
  return (
    <ThemeProvider theme={colorTheme}>
      <HashLink to={props.url}>
        <MuiButton variant='contained' color='turquoiseBlue' sx={{
          fontSize: '15px',
          letterSpacing: '0.1em',
          color: 'white',
          borderRadius: '0',
          width: '75%',
          padding: '12px 3vw 12px 6vw',
          justifyContent: 'space-between'
        }}>
          {props.text}
          <ArrowForwardIosIcon sx={{ height: '15px' }} />
        </MuiButton>
      </HashLink>
    </ThemeProvider>
  );
}
