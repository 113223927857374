import * as React from 'react';
import { Container } from '@mui/system';

export default function ContentsImage(props) {
    return (
        <Container maxWidth="lg">
            <img
                src={process.env.PUBLIC_URL+props.imageSrc}
                width={props.width}
                height={props.height}
                alt={props.alt}
                style={{
                    borderRadius: "10px",
                }}
            >
            </img>
        </Container>
    );
  }