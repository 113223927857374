import * as React from 'react';
import Tag from '../../components/Tag';
import { Box, Typography } from '@mui/material';
import Footer from './footer';
import ScrollToTopIcon from '../../components/ScrollToTopIcon';

export default function Contact(props) {
  React.useEffect(() => {
    if (props.pageTitle !== undefined && props.pageTitle !== null) {
      document.title = props.pageTitle + `｜コラボテクノ株式会社`;
    }
  });
  return (
      <body>
        <div class="wrapper clearfix">
          <article class="onethird">
            <div class="spwrapper">
              <div class="companybox clearfix">
                <h3 class="consen">お問い合わせ</h3>
                  <Box>
                    <Typography fontSize={"14px"} style={{ color: '#000', paddingBottom: '1.5rem', lineHeight: '160%' }}>
                      {"弊社に関するお問合せについては、すべてメールで受け付けております。"}
                    </Typography>
                    <div class="company-contents-with-tag">
                      <Box>
                        <Tag tagText={"開発のご依頼・開発のパートナーに関して"} />
                      </Box>
                      <Box sx={{ margin: '10px 0px 0px -5px' }}>
                        <Box style={{
                            padding: '15px 45px 15px 45px',
                            textAlign: 'left',
                            display: 'flex',
                            justifyContent: 'space-between',
                            flexDirection: 'column'
                        }}>
                          <a href="mailto:partner@colla-tech.co.jp" style={{ color: 'inherit' }}>partner@colla-tech.co.jp</a>
                        </Box>
                      </Box>
                    </div>
                    <div class="company-contents-with-tag">
                      <Box>
                        <Tag tagText={"採用に関するお問い合わせ"} />
                      </Box>
                      <Box sx={{ margin: '10px 0px 0px -5px' }}>
                        <Box style={{
                            padding: '15px 45px 15px 45px',
                            textAlign: 'left',
                            display: 'flex',
                            justifyContent: 'space-between',
                            flexDirection: 'column'
                        }}>
                          <a href="mailto:recruit@colla-tech.co.jp" style={{ color: 'inherit' }}>recruit@colla-tech.co.jp</a>
                        </Box>
                      </Box>
                    </div>
                    <div class="company-contents-with-tag">
                      <Box>
                        <Tag tagText={"ライブ配信サポートに関するお問い合わせ"} />
                      </Box>
                      <Box sx={{ margin: '10px 0px 0px -5px' }}>
                        <Box style={{
                            padding: '15px 45px 15px 45px',
                            textAlign: 'left',
                            display: 'flex',
                            justifyContent: 'space-between',
                            flexDirection: 'column'
                        }}>
                          <a href="mailto:stream@colla-tech.co.jp" style={{ color: 'inherit' }}>stream@colla-tech.co.jp</a>
                        </Box>
                      </Box>
                    </div>
                    <div class="company-contents-with-tag">
                      <Box>
                        <Tag tagText={"その他"} />
                      </Box>
                      <Box sx={{ margin: '10px 0px 0px -5px' }}>
                        <Box style={{
                            padding: '15px 45px 15px 45px',
                            textAlign: 'left',
                            display: 'flex',
                            justifyContent: 'space-between',
                            flexDirection: 'column'
                        }}>
                          <a href="mailto:info@colla-tech.co.jp" style={{ color: 'inherit' }}>info@colla-tech.co.jp</a>
                        </Box>
                      </Box>
                    </div>
                    <Typography style={{ textAlign: 'left', padding: '10px 0' }}>担当者より改めてご連絡をさせていただきます。</Typography>
                  </Box>
              </div>
            </div>
          </article>
        </div>
        <ScrollToTopIcon />
        <Footer />
      </body>
    )
}
