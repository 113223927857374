import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import { Link as Scroll } from 'react-scroll';
import { useEffect, useState } from 'react'

export default function ScrollToTopIcon() {
  const [isButtonActive, setIsButtonActive] = useState(false);
  useEffect(() => {
    window.addEventListener('scroll', scrollWindow)
    return () => {
      window.removeEventListener('scroll', scrollWindow)
    }
  }, [])
  const scrollWindow = () => {
    const top = 100  //ボタンを表示させたい位置
    let scroll = 0
    scroll = window.scrollY
    if (top <= scroll) {
      setIsButtonActive(true)
    } else {
      setIsButtonActive(false)
    }
  }
  const normalStyle = {
    opacity: 0,
    transition: '0.5s',
    pointerEvents: 'none'
  }
  const activeStyle = {
    opacity: 0.9,
    transition: '0.5s',
    cursor: 'pointer',
    position: 'fixed',
    bottom: '33px',
    right: '2vw',
    zIndex: '10',
  }
  const style = isButtonActive ? activeStyle : normalStyle;

  return (
    <Scroll className='scrollToTopButton' to="top" smooth={true} duration={600} style={style}><KeyboardArrowUpIcon sx={{ fontSize: 50, backgroundColor: 'lightgray', borderRadius: '50px' }} /></Scroll>
  )
}