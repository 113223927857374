import * as React from "react";
import MuiAccordion from "@mui/material/Accordion";
import MuiAccordionSummary from "@mui/material/AccordionSummary";
import MuiAccordionDetails from "@mui/material/AccordionDetails";
import { ThemeProvider } from "@mui/material/styles";
import { colorTheme } from "../const";
import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";
import { Typography } from "@mui/material";
import { Box } from "@mui/system";
import EntryBotton from "./EntryButton";

export default function AccordionRequirementsNewGraduates(props) {
  const [show, toggleShow] = React.useState(false);
  const [isExpanded, switchIsExpanded] = React.useState(true);

  return (
    <ThemeProvider theme={colorTheme}>
      <Box
        sx={{
          padding: {xs: "10% 5%", sm: "10% 5%", md: "5%"},
          width: { xs: "80%", sm: "80%", md: "75%", lg: "65%", xl: "45%" },
          margin: "0 auto",
        }}
      >
        <Box
          sx={{ fontSize:{ xs: "3.5vw", sm: "3.5vw", md: "3vw", lg:"1.5vw", xl: "1.5vw" }, padding: { xs: "3% 2%", lg: "2% 0" } }}
          style={{
            padding: "3% 2%",
            borderWidth: "1",
            border: `0.4vw solid ${colorTheme.palette.turquoiseBlue.main}`,
            backgroundColor: "white",
            color: `${colorTheme.palette.turquoiseBlue.main}`,
            width: "50%",
            textAlign: "",
            whiteSpace: "pre-warp",
          }}
        >
          {props.category}
        </Box>
        <Typography
          sx={{fontSize:{ xs: "5vw", sm: "5vw", md: "3vw", lg:"2vw", xl: "2vw" }}}
          style={{ padding: "5% 0px 3% 0px" }}
          fontWeight={"bold"}
          textAlign={"left"}
        >
          {props.Menutext}<br />
          <br />
          <Typography sx={{ fontSize:{ xs: "3.2vw", sm: "3.2vw", md: "3vw", lg:"1.2vw", xl: "1vw" }, textAlign: 'left' }}>
            <div style={{ fontWeight:"bold" }}>【26卒選考開始】</div>
            2024年10月予定～<br />
            　2025年5月には終了予定<br />
            <br />
            選考の流れ①（2025年1月まで）<br />
            プログラミング体験イベント参加<br />
            ↓<br />
            エントリー<br />
            ↓<br />
            オンライン人事面接（カジュ面）<br />
            ↓<br />
            オンライン座談会（コラボエンジニアとの接点）<br />
            SPI受験<br />
            ↓<br />
            代表との最終面接<br />
            <br />

            選考の流れ②（2025年2月～）<br />
            代表とのカジュアル面談<br />
            ↓<br />
            人事との一次面接<br />
            ↓<br />
            オンライン座談会<br />
            SPI受験<br />
            場合によって、論理的思考力を確認するテスト＆面談<br />
            ↓<br />
            代表との最終面接<br />
            <br />
          </Typography>
          <br />
          <Typography sx={{ fontSize:{ xs: "3.2vw", sm: "3.2vw", md: "3vw", lg:"1.2vw", xl: "1vw" }, textAlign: 'left'  }}>
            <div style={{ fontWeight:"bold" }}>【入社後1年間の流れ】</div>
            ４月～６月：Java研修<br />
            　　　　　　新歓BBQ<br />
            ７月～　　：先輩とともに現場でOJT研修<br />
            １０月～　：正式配属<br />
            　　　　　　自社採用活動イベントの協力<br />
            　　　　　　社内イベント企画・実行<br />
            ３月　　　　：成果発表<br />
            ※メンター制度導入のため各人にメンターがつきます<br />
            ※入社後社内チームに所属し、チームリーダーとの１on１が毎月あります
          </Typography>
        </Typography>
        <Typography
          sx={{fontSize:{ xs: "3.2vw", sm: "3.2vw", md: "3vw", lg:"1vw", xl: "1vw" }}}
          textAlign={"left"}
          style={{ padding: "0px 0px 7% 0px" }}
        >
          <ul
            style={{
              padding: "0",
              listStyle: "none",
            }}
          >
            {props.Menudetail.map((belonging) => (
              <li  style={{
                // margin:"3% 0 3% 0"
              }}key={belonging}>{belonging}</li>
            ))}
          </ul>
        </Typography>

        <MuiAccordion
          onClick={() => toggleShow(!show)}
          toggle={true}
          onChange={() => switchIsExpanded(!isExpanded)}
          square={true}
          disableGutters={true}
          sx={{
            "& .MuiAccordionSummary-root, .MuiAccordionDetails-root": {
              paddingRight: "2%",
              paddingLeft: "5%",
            },
            "& .MuiAccordionDetails-root": {
              xs: { paddingTop: "3%", paddingBottom: "3%" }, sm: { paddingTop: "3%", paddingBottom: "3%" }, md: { paddingTop: "3%", paddingBottom: "3%" }, lg: { paddingTop: "1%", paddingBottom: "1%" }
            },
            "& .MuiAccordionDetails-root:nth-of-type(odd)": {
              backgroundColor: colorTheme.palette.lightGray.main,
              paddingLeft: "calc(6% - 0.5em)", // 先頭記号"【"左の半角分のスペースを詰めるために指定
            },
            textAlign: "left",
            fontSize: { xs: "3.5vw" , sm: "3vw" , md: "2.3vw", lg: "1vw", xl: "1vw" }
          }}
        >
          <MuiAccordionSummary
            expandIcon={
              isExpanded ? (
                <AddIcon fontSize="large" sx={{ color: "white" }} />
              ) : (
                <RemoveIcon fontSize="large" sx={{ color: "white" }} />
              )
            }
            sx={{
              backgroundColor: `${colorTheme.palette.turquoiseBlue.main}`,
              color: "white",
              fontSize: { xs: "4.5vw" , sm: "4.5vw" , md: "2.3vw", lg: "1.5vw", xl: "1.5vw" },
              padding: {md: "2% 0 2% 0", lg: "0"}
            }}
          >
            募集要項
          </MuiAccordionSummary>

          <MuiAccordionDetails>仕事内容</MuiAccordionDetails>
          <MuiAccordionDetails>{props.jobContent}</MuiAccordionDetails>

          <MuiAccordionDetails>
          応募資格・条件
          </MuiAccordionDetails>
          <MuiAccordionDetails>
          <ul
            style={{
              padding: "0",
              margin: "0",
              listStyle: "none",
            }}
            >
               {props.condition.map((belonging) => (
                <li  style={{
                  margin:"0"
                }}key={belonging}>{belonging}</li>
                ))}
            </ul>
          </MuiAccordionDetails>

          <MuiAccordionDetails>募集人数・募集背景</MuiAccordionDetails>
          <MuiAccordionDetails>{props.recruitingCount}</MuiAccordionDetails>
          <MuiAccordionDetails>勤務地</MuiAccordionDetails>
          <MuiAccordionDetails>{props.workPlace}</MuiAccordionDetails>
          <MuiAccordionDetails>勤務時間</MuiAccordionDetails>
          <MuiAccordionDetails>{props.workTime}</MuiAccordionDetails>
          <MuiAccordionDetails>給与</MuiAccordionDetails>
          <MuiAccordionDetails>{props.salary}</MuiAccordionDetails>

          <MuiAccordionDetails>休日休暇</MuiAccordionDetails>
          <MuiAccordionDetails>
          <ul
            style={{
              padding: "0",
              margin:"0",
              listStyle: "none",
            }}
            >
               {props.holiday.map((belonging) => (
                <li  style={{
                  margin:"0"
                }}key={belonging}>{belonging}</li>
                ))}
            </ul>
          </MuiAccordionDetails>

          <MuiAccordionDetails>福利厚生</MuiAccordionDetails>
          <MuiAccordionDetails>
          <ul
            style={{
              padding: "0",
              margin:"0",
              listStyle: "none",
            }}
            >
               {props.benefit.map((belonging) => (
                <li  style={{
                  margin:"0"
                }}key={belonging}>{belonging}</li>
                ))}
            </ul>
          </MuiAccordionDetails>
        </MuiAccordion>
        {/* 募集要項buttonを押すことで表示 */}
        <div>
          {show && (
            <Box sx={{ marginTop: "10%" }}>
              <EntryBotton text={"応募する"} url={props.url}></EntryBotton>
            </Box>
          )}
        </div>
      </Box>
    </ThemeProvider>
  );
}