import { Box } from '@mui/material';
import * as React from 'react';
import { ThemeProvider } from '@mui/material/styles';
import { colorTheme } from '../const';
import { useMediaQuery } from 'react-responsive'

export default function Tag(props) {
  const isMobileScreen = useMediaQuery({ query: '(max-width: 760px)' })

  function tagBox(tagStyle, tagText) {
    return (
      <span style={{ float: tagStyle === 'right' ? 'right' : 'left' }}>
        <Box sx={{
          display: 'inline-block',
          top: 0,
          //propsでrightを指定した場合とそれ以外の場合で余白が変化します
          padding: tagStyle === 'right' ? '5px 40px 5px 16px' : '5px 16px 5px 40px',
          height: '25px',
          lineHeight: '25px',
          //スマホの場合、画面サイズに合わせて文字のサイズが変化します
          fontSize: isMobileScreen ? '2.5vw' : '14px',
          backgroundColor: `${colorTheme.palette.turquoiseBlue.main}`,
          color: 'white',
          whiteSpace: 'pre-line',
          textAlign: 'left'
        }}>
          {tagText}
        </Box>
      </span>
    )
  }
  return (
    <ThemeProvider theme={colorTheme}>
      {tagBox(props.tagStyle, props.tagText)}
    </ThemeProvider>
  )
}