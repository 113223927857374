import * as React from 'react';
import MuiButton from '@mui/material/Button';
import { ThemeProvider } from '@mui/material/styles';
import { colorTheme } from '../const';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';

export default function Button(props) {

    const redirectToForm = () => {
      window.open(props.url);
    };

  return (
    <ThemeProvider theme={colorTheme}>
        <MuiButton variant='contained' color='turquoiseBlue' sx={{
          fontSize: { xs: "3vw" , sm: "3vw" , md: "2.3vw", lg: "2vw", xl: "1.5vw" },
          letterSpacing: '0.1em',
          color: 'white',
          borderRadius: '0',
          width: '75%',
          padding: '12px 3vw 12px 6vw',
          justifyContent: 'space-between'
        }} onClick={redirectToForm}>
          {props.text}
          <ArrowForwardIosIcon sx={{ height: '15px' }} />
        </MuiButton>
    </ThemeProvider>
  );
}