import { autocompleteClasses, Box, Typography, Grid } from '@mui/material';
import * as React from 'react';
import { ThemeProvider } from '@mui/material/styles';
import { colorTheme } from '../const';
import ButtonCircle from "./ButtonCircle";

export default function ContentsBoxWithTagAndImage(children) {
  return (
    <ThemeProvider theme={colorTheme}>
      <Box sx={{
        width: autocompleteClasses,
        height: autocompleteClasses,
        backgroundColor: 'inherit',
        whiteSpace: 'pre-line',
        alignItems: "right"
      }}>
        <Box sx={{ flexGrow: 1 }}>
          <Grid container spacing={1}>
            <Grid item xs={12} sm={6} md={6} >
              <Box sx={{ padding: '10% 0 0 0' }}>
                <img src={children.imageSrc} width="90%" alt=""></img>
              </Box>
            </Grid>
            <Grid item xs={12} sm={6} md={6}>
              <Box style={{ width: { xs: "60%", sm: "70%", md: "100%", xl: "100%" }, padding: '18% 0 5% 15%', textAlign: 'left' }}>
                <Typography variant="h2" sx={{ fontSize: { xs: "20px", sm: "18px", md: "27px", lg: "30px", xl: "2vw" } }} style={{ padding: '0px 0 3% 0', color: `${colorTheme.palette.navy.main}` }} fontWeight={'bold'}>{children.title}</Typography>
                <Typography variant="h2" sx={{ fontSize: { xs: "20px", sm: "15px", md: "20px", lg: "30px", xl: "2vw" } }} style={{ padding: '4% 0px 3% 0' }} >{children.text}</Typography>
                <Typography variant="h2" sx={{ fontSize: { xs: "12px", sm: "15px", md: "20px", lg: "25px", xl: "1.2vw" } }} style={{ padding: '0px 7% 5% 0' }} fontWeight={"weight "}>{children.subtext}</Typography>
                <Box sx={{ width:"100%", padding: { xs: "0 0 0 0", sm: "10% 0% 0 0", md: "0 0 0 0", xl: "0 0 0 0" } }}>
                  <ButtonCircle
                    url={children.url}
                    text={children.buttonText}
                  ></ButtonCircle>
                </Box>
              </Box>
            </Grid>
          </Grid>
        </Box>
      </Box>
    </ThemeProvider>

  )
}
