import { Box, Typography, Grid, autocompleteClasses } from '@mui/material';
import * as React from 'react';
import { colorTheme } from '../const';
import CompanyImg from '../image/web-photo-01.png';
import CompanyCenterImg from '../image/IMG_9786.png';
import { styled } from '@mui/material/styles';
import Paper from '@mui/material/Paper';
import YoshidaImg from '../image/IMG_0670.jpg';
import ContentsBox from '../components/ContentsBox';
import ContentsBoxWithTagAndIcon from '../components/ContentsBoxWithTagAndIcon';
import ContentsBoxWithTagAndImage from '../components/ContentsBoxWithTagAndImage';
import Tag from '../components/Tag';
import ScrollToTopIcon from './../components/ScrollToTopIcon';

const test = "システム構築は誰にでもできることではないのに、それを担うエンジニアたちが充分に評価されていない。コラボテクノを創業したのは、エンジニアの能力や技術、個性が正当に評価され、その成長と目標達成を支援したかったからです。\n\nこの会社はまだまだ小さく、若い会社です。しかし、だからこそ、一人ひとりに目が行き届く、個人の意見を尊重する、成長をサポートする環境であると自負しています。\n\n自ら5年後、10年後の目標を定め、主体的に経験を積み、達成への道のりを自分らしく歩んでいく。なりたい将来像をより早く実現させるための支援を、コラボテクノは惜しみません。そんな会社で働きたい方は、ぜひ私たちのもとへ"

const missionText = "ビジネスの持続可能な成長のためには、トレンドが目まぐるしく変化するITの更新が必要不可欠です。コラボテクノは、質の高いエンジニアによるスキルと知見を提供し、お客様のビジネス革新を支えています。"
const visionText = "エンジニアの活躍フィールドを広げ、一人ひとりが自分の個性と可能性を伸ばし、その個性と可能性の発揮によって、お客様や社会に貢献していく会社を目指します。"
const valueText = "本当にやりたいことならば、機会が来るのを待つのではなく自分から機会を創りにいく。そして、その作り出した機会が実るか実らないかは問題ではなく、成功しようと不発で終わろうと機会を創りだしたことそのものが、自らを成長させるのです。"

const Item = styled(Paper)(({ theme }) => ({
  ...theme.typography.body2,
  padding: theme.spacing(0)
}));

export default function AboutCompany(props) {
  React.useEffect(() => {
    if (props.pageTitle !== undefined && props.pageTitle !== null) {
      document.title = props.pageTitle + `｜コラボテクノ株式会社`;
    }
  });
  return (
    <Box className='App'>
      <Box sx={{
        backgroundColor: `${colorTheme.palette.creamGray.main}`,
      }}>
        <img src={process.env.PUBLIC_URL + CompanyImg} width='100%' alt='インタビュートップ画像'></img>

        <Box sx={{ padding: "3% 0 0 0", background: `${colorTheme.palette.creamGray.main}` }}>
          <Box sx={{ borderRadius: '0px 20px 20px 0px', padding: "2% 0 0 0", width: "90%", background: { sm: "white", sx: `${colorTheme.palette.creamGray.main}` } }}>
            <Tag tagStyle={"left"} tagText={"Corporate Philosophy"} />
            <Grid container Spacing={1} >
                <Typography sx={{ display: { xs: "block", sm: "block", md: "block" }, fontSize: {xs: "17px",sm:"22px", md: "30px", lg: "38px", xl: "2vw" }, textAlign: 'left', padding: "3% 0 2% 10%" , color: `${colorTheme.palette.navy.main}` }} fontWeight={'bold'}>
                  <div>{"働く人が自分自身の可能性を追求でき"}</div><div>{"自己実現の場として利用できる会社"}</div>
                </Typography>
            </Grid>
          </Box>
        </Box>
        <Box sx={{ padding: "3% 0 0 0", background: `${colorTheme.palette.creamGray.main}` }}>
          <Box sx={{ borderRadius: '0px 20px 20px 0px', padding: "2% 0 0 0", width: "90%", background: { sm: "white", sx: `${colorTheme.palette.creamGray.main}` } }}>
            <Tag tagStyle={"left"} tagText={"MISSON"} />
            <Grid container Spacing={1} >
              <Grid item sm={6} md={6} xl={5}>
                <Typography sx={{ display: { xs: "block", sm: "block", md: "block" }, fontSize: {xs: "20px",sm:"22px", md: "30px", lg: "38px", xl: "2vw" }, textAlign: 'left', px: { xs: 4, sm: 4, md: 7, lg: 5, xl: 10 }, padding: "5% 0 2% 0" , color: `${colorTheme.palette.navy.main}` }} fontWeight={'bold'}>
                  <div>{"お客さまの"}</div><div>{"ビジネス革新を"}</div>{"ITでサポートする。"}
                </Typography>
              </Grid>
              <Grid sm={6} md={6} xl={5}>
                <Typography variant="h6" width="90%" sx={{ display: { xs: "block", sm: "block", md: "block" }, fontSize: { xs: "12px", sm: "15px" ,md: "20px", lg: "23px", xl: "1.3vw" }, textAlign: 'left', px: { xs: 4, sm: 2, md: 2 }, padding: "3% 0 4% 0" }}>
                  {missionText}
                </Typography>
              </Grid>
            </Grid>
          </Box>
        </Box>
        <Box sx={{ padding: "3% 0 0 0", background: `${colorTheme.palette.creamGray.main}` }}>
          <Box sx={{ borderRadius: '0px 20px 20px 0px', padding: "2% 0 0 0", width: "90%", background: { sm: "white", sx: `${colorTheme.palette.creamGray.main}` } }}>
            <Tag tagStyle={"left"} tagText={"VISION"} />
            <Grid container Spacing={1} >
              <Grid sm={6} md={6} xl={5}>
                <Typography sx={{ display: { xs: "block", sm: "block", md: "block" }, fontSize: { xs: "20px", sm:"25px",md: "35px", lg: "50px", xl: "2vw" }, textAlign: 'left', px: { xs: 3, sm: 3, md: 7, lg: 5, xl: 10 }, padding: "5% 0 2% 0", color: `${colorTheme.palette.navy.main}` }} fontWeight={'bold'}>
                  <div>{"「私」が生きる、"}</div>{"「私」を活かす。"}
                </Typography>
              </Grid>
              <Grid sm={6} md={6} xl={5}>
                <Typography variant="h6" width="90%" sx={{ display: { xs: "block", sm: "block", md: "block" }, fontSize: {xs: "12px", sm: "15px" , md: "20px", lg: "25px", xl: "1.3vw" }, textAlign: 'left', px: { xs: 4, sm: 2, md: 2 }, padding: "4% 0 4% 0" }}>
                  {visionText}
                </Typography>
              </Grid>
            </Grid>
          </Box>
        </Box>
        <Box sx={{ padding: "3% 0 4% 0", background: `${colorTheme.palette.creamGray.main}` }}>
          <Box sx={{ borderRadius: '0px 20px 20px 0px', padding: "2% 0 0 0", width: "90%", background: { sm: "white", sx: `${colorTheme.palette.creamGray.main}` } }}>
            <Tag tagStyle={"left"} tagText={"VALUE"} />
            <Grid container Spacing={1} >
              <Grid item sm={6} md={6} xl={5}>
                <Typography sx={{ display: { xs: "block", sm: "block", md: "block" }, fontSize: {xs: "20px",sm:"22px", md: "30px", lg: "38px", xl: "2vw" }, textAlign: 'left', px: { xs: 4, sm: 4, md: 7, lg: 5, xl: 10 }, padding: "5% 0 2% 0" , color: `${colorTheme.palette.navy.main}` }} fontWeight={'bold'}>
                <div>{"自ら機会を創り出し"}</div><div>{"機会によって"}</div>{"自らを変えよ"}
                </Typography>
              </Grid>
              <Grid sm={6} md={6} xl={5}>
                <Typography variant="h6" width="90%" sx={{ display: { xs: "block", sm: "block", md: "block" }, fontSize: { xs: "10.5px", sm: "15px" ,md: "20px", lg: "23px", xl: "1.3vw" }, textAlign: 'left', px: { xs: 4, sm: 2, md: 2 }, padding: "3% 0 4% 0" }}>
                  {valueText}
                </Typography>
              </Grid>
            </Grid>
          </Box>
        </Box>
        <img src={process.env.PUBLIC_URL + CompanyCenterImg} width='100%' alt='インタビュートップ画像'></img>
        <Box sx={{ flexGrow: 1, px: { xl: "8.5%", md: 5 }, padding: "2% 0 0 0" }}>
          <Grid container spacing={1}>
            <Grid item xs={12} sm={6} md={5.5} xl={5}>
              <ContentsBox
                title={"コラボテクノDATA"}
              ></ContentsBox>
            </Grid>
            <Grid item xs={12} sm={6} md={6.5} xl={5}>
              <ContentsBox
                text={"私たちコラボテクノに関する情報を\n数字でご紹介します。"}>
              </ContentsBox>
            </Grid>
          </Grid>
        </Box>
        <Grid container rowSpacing={10} columnSpacing={{  md: 5 }} sx={{padding:{sm:"0 5% 0 5%",md:"0 5% 0 5%"}}}>
        <Grid item xs={11} sm={6} md={6} lg={6} xl={6}>
            <Item sx={{padding:"0 10% 0 0%"}}>
              <Box sx={{ width: autocompleteClasses, backgroundColor: "white" }}>
                <ContentsBoxWithTagAndIcon imageSrc={`${process.env.PUBLIC_URL}/image/IconImage/GenderImg.png`} tagText={"男女比率"} title={"3：1"} subtitle={'男性　　　女性'} text={'職場に女性が多く、\nバリバリ活躍しています。'} suppText={'※2023年6月集計'}></ContentsBoxWithTagAndIcon>
              </Box>
            </Item>
          </Grid>
          <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
            <Item sx={{margin:"0 0 0 10%"}}>
              <Box sx={{ width: autocompleteClasses, backgroundColor: "white" ,padding:{sm:"0 0 10% 0",md:"0 0 11% 0",xl:"0 0 14% 0"} }}>
                <ContentsBoxWithTagAndIcon imageSrc={`${process.env.PUBLIC_URL}/image/IconImage/AgeImg.png`} tagStyle={'right'} tagText={"平均年齢"} subtitle={'　　　'} title={"32歳"} text={"若い世代が中心で、\n誰ともフランクに話せる会社です。"}></ContentsBoxWithTagAndIcon>
              </Box>
            </Item>
          </Grid>
          <Grid item xs={11} sm={6} md={6} lg={6} xl={6}>
          <Item sx={{padding:"0 10% 0 0%"}}>
              <Box sx={{ width: autocompleteClasses, backgroundColor: "white" }}>
                <ContentsBoxWithTagAndIcon imageSrc={`${process.env.PUBLIC_URL}/image/IconImage/WorkTimeImg.png`} tagText={"平均残業時間（月）"} title={"15.5時間"} text={"忙しいときは残業することもあります。\nもちろん残業代が出ます。"}></ContentsBoxWithTagAndIcon>
              </Box>
            </Item>
          </Grid>
          <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
          <Item sx={{margin:"0 0 0 10%"}}>
              <Box sx={{ width: autocompleteClasses, backgroundColor: "white" ,padding:{md:"0 0 2% 0",lg:"0 0 2.5% 0",xl:"0 0 3% 0"}}}>
                <ContentsBoxWithTagAndIcon imageSrc={`${process.env.PUBLIC_URL}/image/IconImage/RemoteImg.png`} tagStyle={'right'} tagText={"リモートワーク率"} title={"82%"} text={"基本的に在宅で、月に１～２回出社\nというケースが多いです。\n※配属された現場によります"}></ContentsBoxWithTagAndIcon>
              </Box>
            </Item>
          </Grid>
        </Grid>
        <Box sx={{ width: autocompleteClasses, marginTop: "5%", backgroundColor: "white" }}>
          <Tag tagStyle={"left"} tagText={"Massage"}></Tag>
          <ContentsBoxWithTagAndImage tagStyle={"left"} imageSrc={YoshidaImg} tagText={"Message"} title={"代表取締役　吉田 光哉\n\nエンジニアを、もっと、\n評価される職業へ。\n社員個々の目標達成を\nサポートしていく。"} subtext={test} ></ContentsBoxWithTagAndImage>
        </Box>
      </Box >
      <ScrollToTopIcon />
    </Box >
  )
}
