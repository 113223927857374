import { Box, Typography, Grid } from "@mui/material";
import * as React from "react";
import { colorTheme } from "../const";
import Tag from "../components/Tag";
import AccordionRequirementsEntry from "../components/AccordionRequirementsEntry";
import ScrollToTopIcon from '../components/ScrollToTopIcon';

export default function Entry(props) {
  React.useEffect(() => {
    if (props.pageTitle !== undefined && props.pageTitle !== null) {
      document.title = props.pageTitle + `｜コラボテクノ株式会社`;
    }
  });
  return (
    <Box className="App">
      <Box
        sx={{
          backgroundColor: `${colorTheme.palette.creamGray.main}`,
        }}
      >
        <Box
          sx={{
            paddingTop: "5%",
            marginBottom: "0",
            color: `${colorTheme.palette.navy.main}`,
          }}
        >
          <Tag tagStyle={{ float: "left" }} tagText={"Current Openings"} />
          <Grid container Spacing={1} >
            <Typography  sx={{
                fontSize:{ xs: "20px", sm: "30px", md: "30px", lg:"30px", xl: "2vw" },
                textAlign: 'left',
                margin: '0',
                px:{xs:3,sm:1,md:1,xl:2},
                padding:{ xs:"5% 0 0 5%", sm:"5% 0 0 5%", md:"5% 0 0 1%", lg:"1% 0 0 0.5%" },
                color:`${colorTheme.palette.navy.main}`
              }}
              fontWeight={'bold'}
            >
              現在募集中の求人一覧
            </Typography>
          </Grid>
        </Box>

        <Box sx={{ paddingBottom: "10%" }}>

          <Typography id="mid-career" sx={{fontSize:{ xs: "20px", sm: "30px", md: "30px", lg:"30px", xl: "2vw" } ,textAlign: 'left',px:{xs:3,sm:1,md:1,xl:2},padding:"5% 0 0 0",color:`${colorTheme.palette.navy.main}` }} fontWeight={'bold'}>
            中途採用
          </Typography>
          <Box
            sx={{
              backgroundColor: "white",
              marginTop: "5%",
              marginRight: "15%",
              borderTopRightRadius: "10px",
              borderBottomRightRadius: "10px",
            }}
          >
            <AccordionRequirementsEntry
              category="中途採用"
              jobContent1={["【仕事内容】",
                "当社「コラボテクノ」では、今まで大手Sierに対しSES事業を中心に行ってきましたが、",
                "今後、直接、企業のIT支援（DX推進やITコンサルティング）の強化を考えています。",
                "今回は、この事業領域の拡大に伴い、",
                "営業経験を活かして当社の成長をサポートしてくださる営業担当者を募集します。",
                "具体的には以下の業務を担当いただきます。",
                "",
                "・新規顧客開拓および既存顧客との関係強化",
                "・受託開発およびSESビジネスの提案営業",
                "・DXやITコンサルティングに関連するプロジェクトの提案、契約交渉、進捗管理",
                "・エンジニアチームとの連携、提案内容に基づいた技術面の調整"
              ]}
              jobContent2={[]}
              jobContent3={[]}
              Menutext="営業"
              Menudetail={[]}
              condition={[
                          "【求めるスキル・経験】",
                          "・IT業界での法人営業経験/無形商材の法人営業経験 5年以上",
                          "■新規開拓や提案型営業の実績",
                          "■DXやITソリューションに対する知識",
                          "　",
                          "【歓迎スキル】",
                          "受託開発やSES営業の経験がある方歓迎",
                          "SIerやSES事業に関連する顧客基盤をお持ちの方",
                          "チームリーダーシップ、プロジェクトマネジメント経験",
                          "当社のレクリエーションイベント等にも積極的に参加いただける方"
                        ]}
              recruitingCount="1名"
              workPlace="東京都港区新橋3-11-8 オーイズミ新橋第2ビル9階"
              workTime={[
                        "完全土日休み",
                        "9:30 ～ 18:30",
                        "休憩時間：12：00〜13：00（60分）",
                        "平均残業時間：平均10時間／月 ※基本残業は多く発生しません。"
                    ]}
              salary={[
                       "年収 500万～800万円（経験・スキルに応じて決定）",
                       "月給は、12分割で支給、見なし固定残業45時間分含む",
                       "　",
                       "【給与詳細】",
                       "・45時間超過分は別途支給",
                       "・別途業績に応じた賞与を支給（年1回）",
                       "・ミッショングレード制をテスト施行中",
                       "（年功序列ではなく、スキルレベルに応じた給料体系となっております。",
                       "・昇給は年１回です。",
                       "（人事考課制度1回、四半期ごとの振り返りでの目標管理をしております。）"
                      ]}
              holiday={["◇ 年間休日120日以上", "◇ 夏季休暇", "◇ 年末年始休暇", "◇ 産前産後休暇・育児休暇制度"]}
              benefit={[
                "◇雇用保険",
                "◇ 厚生年金",
                "◇ 労災保険", 
                "◇ 健康保険", 
                "◇ 交通費支給あり", 
                "◇ テレワーク・在宅OK", 
                "◇ 時短勤務制度あり",
                "◇資格取得支援（受験料・書籍購入・メンター相談）", 
                "◇チーム懇親会費用負担", 
                "◇技術研修・自己啓発研修等キャリアに必要な研修は申請で受講可能", 
                "◇フリーワーク制度（社内副業）"]}
              url="https://forms.gle/nWpW7iaq6XpuzPfR9"
            ></AccordionRequirementsEntry>
          </Box>
        </Box>
      </Box>
      <ScrollToTopIcon />
    </Box>
  );
}
