import * as React from 'react';
import '../../../App.css';
import PersonInterview from '../../../components/PersonInterview';
import NewEmployee3Top from '../../../image/interviews/new_employee3_top.png'
import NewEmployee3Text1 from '../../../image/interviews/new_employee3_text1.png'
import NewEmployee3Text2 from '../../../image/interviews/new_employee3_text2.png'
import NewEmployee3Text3 from '../../../image/interviews/new_employee3_text3.png'
import { useMediaQuery } from 'react-responsive';

export default function NewEmployeeInterview3(props) {
  React.useEffect(() => {
    if (props.pageTitle !== undefined && props.pageTitle !== null) {
      document.title = props.pageTitle + `｜コラボテクノ株式会社`;
    }
  });
  const isMobileScreen = useMediaQuery({ query: '(max-width: 760px)' })
  return (
    <PersonInterview
      nextUrl={"/recruit/member/job-change-interview1"}
      tagStyle={{ float: 'left' }}
      tagText={"INTERVIEW"}
      name={"田本 和之"}
      entryYear={"2022"}
      title={isMobileScreen ? "先生はYouTube、\n就活中の急転チャレンジ。" : "先生はYouTube、\n就活中の\n急転チャレンジ。"}
      imageSrcTop={NewEmployee3Top}
      text1={"就活に関しては最初、食品メーカー志望で、しかも大手への就職を狙っていました。エンジニア職はまったく頭になかったのが、正直なところです。でも、大手の食品メーカーは倍率がとても高く、エントリーしてもお祈りメールばかりで（笑）。就活の厳しさを味わいながら、このまま続けて、仮に小さな食品メーカーに就職したとして、その先に大企業につながるルートはあるのだろうかと、ふと思ってしまって。自分の将来像を再度整理したときに、自分は大きな企業の大きなプロジェクトに携わりたいということがわかって、それだったら手に職、スキルがあった方が可能性が高いのではと考えました。IT業界にはスキル勝負のイメージを持っていて、エンジニアはどうだろう、と。調べてみると文系、未経験でも大丈夫そうなことがわかり、やってみるのもありかな、という感じです。\n\nとはいえ、周りは公務員志望ばかりで参考にならず、IT業界に知り合いもいないので、まったくのゼロスタートでした。勉強はYouTubeとか色々見て、勉強のサイトを知って、有名なところを触ってみて。どうかな、自分にできるかな、と感触を確かめつつ、できなくはないな、くらいの感覚はつかめたので、よしやろうと方向転換しました。"}
      imageSrcText1={NewEmployee3Text1}
      text2Head={"こだわったのは、\n使えるスキルが身につく実務経験。"}
      text2Body={"実際、途中で切り替えたので求人は少なくなっていましたが、それでも相当数の企業にアプローチしました。困ったのは、ガクチカです。食品メーカー狙いの頃は、「学生時代に力を入れたことは、魚を捌くこと。いろんな魚を捌けるようになりました」と書いていて、ちょっと弱いかなと思いつつ、答える準備はできていました。エンジニアの場合は準備する時間もなく、作り話をするくらいならさらけ出そうと覚悟を決めて、「タイピングから練習中です」と。今から取り組んでいることを、その経過とともに書きました。それが功を奏したかどうかは、わかりません（笑）\n\nコラボテクノに決めたのは、未経験からでも、スキルを実践で身につけられそうだからです。もちろん、しっかりした研修期間ありきですが、結局のところ実務で経験を積まないと、本当のスキルとは言えないと思うので、そこを大切にしました。先輩たちとの年齢が比較的近く、フランクに話せる社風であることもポイント高かったです。"}
      imageSrcText2={NewEmployee3Text2}
      text3Head={"フルスタックエンジニアをめざして、\n人生を自分で切り開く。"}
      text3Body={"今はOJT中で、先輩の指導のもと、実務を経験しながら必要なスキルを得ようと奮闘しているところです。毎日、新しいことが身についているという実感があります。このまま色々なスキルを自分のものにして、いずれはアプリの設計からリリースまで、一人でもできるようなフルスタックエンジニアを目指したい。5年後、10年後には自分のスキルを頼みに、独立・起業なんてのも楽しそうですね。大手企業からの案件が舞い込むような。\n\n就活当初の食品メーカー志望の頃は想像もしていなかった、良くも悪くも自分次第で人生を変えられる、エンジニアという職業選択。それが間違いではなかったと、今は確信しています。"}
      imageSrcText3={NewEmployee3Text3} />
  );
}