import * as React from "react";
import MuiAccordion from "@mui/material/Accordion";
import MuiAccordionSummary from "@mui/material/AccordionSummary";
import MuiAccordionDetails from "@mui/material/AccordionDetails";
import { ThemeProvider } from "@mui/material/styles";
import { colorTheme } from "../const";
import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";
import { Typography } from "@mui/material";
import { Box } from "@mui/system";
import EntryBotton from "../components/EntryButton";

export default function AccordionRequirements(props) {
  const [show, toggleShow] = React.useState(false);

  const [isExpanded, switchIsExpanded] = React.useState(true);


  return (
    <ThemeProvider theme={colorTheme}>
      <Box
        sx={{
          padding: {xs: "10%", sm: "10%", md: "5%"},
          width: { xs: "80%", sm: "85%", md: "90%", lg: "85%", xl: "70%" },
          margin: "0 auto",
        }}
      >
        <Box
          sx={{ fontSize: { xs: "3vw", sm: "3vw", md: "2.8vw", lg: "1.3vw", xl: "1.3vw" }, padding: { xs: "3% 2%", lg: "2% 0" } }}
          style={{
            padding: "3% 2%",
            borderWidth: "1",
            border: `0.4vw solid ${colorTheme.palette.turquoiseBlue.main}`,
            backgroundColor: "white",
            color: `${colorTheme.palette.turquoiseBlue.main}`,
            width: "50%",
            textAlign: "",
            whiteSpace: "pre-warp",
          }}
        >
          {props.category}
        </Box>
        <Typography
          sx={{fontSize:{ xs: "5vw", sm: "5vw", md: "3vw", lg:"2vw", xl: "2vw" }}}
          style={{ padding: "5% 0px 3% 0px" }}
          fontWeight={"bold"}
          textAlign={"left"}
        >
          {props.Menutext}<div>{props.MenuSubtext}</div>
        </Typography>
        <Typography
          sx={{fontSize:{ xs: "3.2vw", sm: "3.2vw", md: "3vw", lg:"1vw", xl: "1vw" }}}
          textAlign={"left"}
          style={{ padding: "0px 0px 7% 0px" }}
        >
          <ul
            style={{
              padding: "0",
              listStyle: "none",
            }}
          >
            {props.Menudetail.map((belonging) => (
              <li  style={{
                // margin:"3% 0 3% 0"
              }}key={belonging}>{belonging}</li>
            ))}
          </ul>
        </Typography>

        <MuiAccordion
          onClick={() => toggleShow(!show)}
          toggle={true}
          onChange={() => switchIsExpanded(!isExpanded)}
          square={true}
          disableGutters={true}
          sx={{
            "& .MuiAccordionSummary-root, .MuiAccordionDetails-root": {
              paddingRight: "2%",
              paddingLeft: "5%",
            },
            "& .MuiAccordionDetails-root": {
              xs: { paddingTop: "3%", paddingBottom: "3%" }, sm: { paddingTop: "3%", paddingBottom: "3%" }, md: { paddingTop: "3%", paddingBottom: "3%" }, lg: { paddingTop: "1%", paddingBottom: "1%" }
            },
            "& .MuiAccordionDetails-root:nth-of-type(odd)": {
              backgroundColor: colorTheme.palette.lightGray.main,
              paddingLeft: "calc(6% - 0.5em)", // 先頭記号"【"左の半角分のスペースを詰めるために指定
            },
            textAlign: "left",
            fontSize: { xs: "3.5vw" , sm: "3vw" , md: "2.3vw", lg: "1vw", xl: "1vw" }
          }}
        >
          <MuiAccordionSummary
            expandIcon={
              isExpanded ? (
                <AddIcon fontSize="large" sx={{ color: "white" }} />
              ) : (
                <RemoveIcon fontSize="large" sx={{ color: "white" }} />
              )
            }
            sx={{
              backgroundColor: `${colorTheme.palette.turquoiseBlue.main}`,
              color: "white",
              fontSize: { xs: "4.5vw" , sm: "4.5vw" , md: "2.3vw", lg: "1.5vw", xl: "1.5vw" },
              padding: {md: "2% 0 2% 0", lg: "0"}
            }}
          >
            募集要項
          </MuiAccordionSummary>

          <MuiAccordionDetails>仕事内容</MuiAccordionDetails>
          <MuiAccordionDetails 
          sx={{
            fontSize: { xs: "1.3vw", sm: "1.5vw", md: "1.6vw", lg: "1.6vw", xl: "1.2vw" }
          }}
          >
            <ul
              style={{
                padding: "0",
                margin:"0",
                listStyle: "none",
              }}
              >
               {props.jobContent1.map((belonging) => (
                <li  style={{
                  margin:"0"
                }}key={belonging}>{belonging}</li>
                ))}
                <br />
                {props.jobContent2.map((belonging) => (
                <li  style={{
                  margin:"0"
                }}key={belonging}>{belonging}</li>
                ))}
                <br />
                {props.jobContent3.map((belonging) => (
                <li  style={{
                  margin:"0"
                }}key={belonging}>{belonging}</li>
                ))}
            </ul>
          </MuiAccordionDetails>

          <MuiAccordionDetails>
          応募資格・条件
          </MuiAccordionDetails>
          <MuiAccordionDetails
          sx={{
            fontSize: { xs: "1.3vw", sm: "1.5vw", md: "1.6vw", lg: "1.6vw", xl: "1.2vw" }
          }}>
          <ul
            style={{
              padding: "0",
              margin:"0",
              listStyle: "none",
            }}
            >
               {props.condition.map((belonging) => (
                <li  style={{
                  margin:"0"
                }}key={belonging}>{belonging}</li>
                ))}
            </ul>
          </MuiAccordionDetails>
          <MuiAccordionDetails>募集人数・募集背景</MuiAccordionDetails>
          <MuiAccordionDetails
          sx={{
            fontSize: { xs: "1.3vw", sm: "1.5vw", md: "1.6vw", lg: "1.6vw", xl: "1.2vw" }
          }}>
            {props.recruitingCount}
          </MuiAccordionDetails>
          <MuiAccordionDetails>勤務地</MuiAccordionDetails>
          <MuiAccordionDetails
          sx={{
            fontSize: { xs: "1.3vw", sm: "1.5vw", md: "1.6vw", lg: "1.6vw", xl: "1.2vw" }
          }}>
              {props.workPlace}
          </MuiAccordionDetails>
          <MuiAccordionDetails>勤務時間</MuiAccordionDetails>
          <MuiAccordionDetails
          sx={{
            fontSize: { xs: "1.3vw", sm: "1.5vw", md: "1.6vw", lg: "1.6vw", xl: "1.2vw" }
          }}>
          <ul
            style={{
              padding: "0",
              margin:"0",
              listStyle: "none",
            }}
            >
               {props.workTime.map((belonging) => (
                <li  style={{
                  margin:"0"
                }}key={belonging}>{belonging}</li>
                ))}
            </ul>
          </MuiAccordionDetails>
          
          <MuiAccordionDetails>給与</MuiAccordionDetails>
          <MuiAccordionDetails
          sx={{
            fontSize: { xs: "1.3vw", sm: "1.5vw", md: "1.6vw", lg: "1.6vw", xl: "1.2vw" }
          }}>
          <ul
            style={{
              padding: "0",
              margin:"0",
              listStyle: "none",
            }}
            >
               {props.salary.map((belonging) => (
                <li  style={{
                  margin:"0"
                }}key={belonging}>{belonging}</li>
                ))}
            </ul>
          </MuiAccordionDetails>

          <MuiAccordionDetails>休日休暇</MuiAccordionDetails>
          <MuiAccordionDetails
          sx={{
            fontSize: { xs: "1.3vw", sm: "1.5vw", md: "1.6vw", lg: "1.6vw", xl: "1.2vw" }
          }}>
          <ul
            style={{
              padding: "0",
              margin:"0",
              listStyle: "none",
            }}
            >
               {props.holiday.map((belonging) => (
                <li  style={{
                  margin:"0"
                }}key={belonging}>{belonging}</li>
                ))}
            </ul>
          </MuiAccordionDetails>

          <MuiAccordionDetails>福利厚生</MuiAccordionDetails>
          <MuiAccordionDetails
          sx={{
            fontSize: { xs: "1.3vw", sm: "1.5vw", md: "1.6vw", lg: "1.6vw", xl: "1.2vw" }
          }}>
          <ul
            style={{
              padding: "0",
              margin:"0",
              listStyle: "none",
            }}
            >
               {props.benefit.map((belonging) => (
                <li  style={{
                  margin:"0"
                }}key={belonging}>{belonging}</li>
                ))}
            </ul>
          </MuiAccordionDetails>

          {/* <MuiAccordionDetails>【期間】</MuiAccordionDetails>
          <MuiAccordionDetails>{props.period}</MuiAccordionDetails>
          <MuiAccordionDetails>【職種】</MuiAccordionDetails>
          <MuiAccordionDetails>{props.occupation}</MuiAccordionDetails>
          <MuiAccordionDetails>【内容】</MuiAccordionDetails>
          <MuiAccordionDetails>{props.content}</MuiAccordionDetails>
          <MuiAccordionDetails>
            【必要な条件（知識、技術）】
          </MuiAccordionDetails>
          <MuiAccordionDetails>
          <ul
            style={{
              padding: "1% 0 1% 0",
              listStyle: "none",
            }}
            >
               {props.condition.map((belonging) => (
                <li  style={{
                  margin:"3% 0 3% 0"
                }}key={belonging}>{belonging}</li>
                ))}
            </ul>
          </MuiAccordionDetails>
          <MuiAccordionDetails>【実習生が用意するもの】</MuiAccordionDetails>
          <MuiAccordionDetails>
            <ul
            style={{
              padding: "0",
              listStyle: "none",
            }}
            >
              {props.belongings.map((belonging) => (
                <li  style={{
                  padding: "2% 0 2% 0",
                }}key={belonging}>・{belonging}</li>
                ))}
            </ul>
          </MuiAccordionDetails>
          <MuiAccordionDetails>【選考方法】</MuiAccordionDetails>
          <MuiAccordionDetails>{props.selection}</MuiAccordionDetails> */}
        </MuiAccordion>
        {/* 募集要項buttonを押すことで表示 */}
        <div>
          {show && (
            <Box sx={{ marginTop: "10%" }}>
              <EntryBotton text={"応募する"} url={props.url}></EntryBotton>
            </Box>
          )}
        </div>
      </Box>
    </ThemeProvider>
  );
}