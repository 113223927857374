import { createTheme } from "@mui/material";

export const colorTheme = createTheme({
  palette: {
    turquoiseBlue: {
      main: "#21BECE"
    },
    blackGray: {
      main: "#312F30"
    },
    lightGray: {
      main: "#D1D2D4"
    },
    creamGray: {
      main: "#ECECEA"
    },
    navy: {
      main: "#25395E"
    }
  }
})