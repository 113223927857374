import { Box, Typography, Grid } from "@mui/material";
import * as React from "react";
import { colorTheme } from "../const";
import Tag from "../components/Tag";
import AccordionRequirementsEntry from "../components/AccordionRequirementsEntry";
import AccordionRequirementsNewGraduates from "../components/AccordionRequirementsNewGraduates";
import ScrollToTopIcon from './../components/ScrollToTopIcon';
import { ThemeProvider } from "@mui/material/styles";
import EntryBotton from "../components/EntryButton";

export default function Entry(props) {
  React.useEffect(() => {
    if (props.pageTitle !== undefined && props.pageTitle !== null) {
      document.title = props.pageTitle + `｜コラボテクノ株式会社`;
    }
  });
  return (
    <Box className="App">
      <Box
        sx={{
          backgroundColor: `${colorTheme.palette.creamGray.main}`,
        }}
      >
        <Box
          sx={{
            paddingTop: "5%",
            marginBottom: "0",
            color: `${colorTheme.palette.navy.main}`,
          }}
        >
          <Tag tagStyle={{ float: "left" }} tagText={"Current Openings"} />
          <Grid container Spacing={1} >
            <Typography  sx={{
                fontSize:{ xs: "20px", sm: "30px", md: "30px", lg:"30px", xl: "2vw" },
                textAlign: 'left',
                margin: '0',
                px:{xs:3,sm:1,md:1,xl:2},
                padding:{ xs:"5% 0 0 5%", sm:"5% 0 0 5%", md:"5% 0 0 1%", lg:"1% 0 0 0.5%" },
                color:`${colorTheme.palette.navy.main}`
              }}
              fontWeight={'bold'}
            >
              現在募集中の求人一覧
            </Typography>
          </Grid>
        </Box>

        <Typography id="new-graduates" sx={{fontSize:{ xs: "20px", sm: "30px", md: "30px", lg:"30px", xl: "2vw" } ,textAlign: 'left',px:{xs:3,sm:1,md:1,xl:2},padding:"5% 0 0 0",color:`${colorTheme.palette.navy.main}` }} fontWeight={'bold'}>
          新卒採用
        </Typography>

        <Box
            sx={{
              backgroundColor: "white",
              marginTop: "5%",
              marginRight: "15%",
              borderTopRightRadius: "10px",
              borderBottomRightRadius: "10px",
            }}
          >
            <AccordionRequirementsNewGraduates
              category="新卒採用"
              jobContent="Webシステム開発"
              Menutext="システムエンジニア"
              Menudetail={[]}
              condition={["■素直に物事を捉えられる方", "■自分で課題設定ができる方", "■課題に対し工夫して取り組める方", "■信頼関係を築くのが上手な方"]}
              recruitingCount="若干数"
              workPlace="東京都港区新橋3-11-8 オーイズミ新橋第2ビル9階(自社内で開発を行う場合と、顧客先で開発を行う場合があります)"
              workTime={["完全土日祝休み 9:30 ～ 18:30"]}
              salary={["年俸　3,240,000円　～　3,600,000円"]}
              holiday={["◇ 年間休日120日以上", "◇ 夏季休暇", "◇ 年末年始休暇", "◇ 産前産後休暇・育児休暇制度"]}
              benefit={["◇雇用保険", "◇ 厚生年金", "◇ 労災保険", "◇ 健康保険", "◇ 交通費支給あり", "◇ テレワーク・在宅OK", "◇ 時短勤務制度あり",
                "◇資格取得支援（受験料・書籍購入・メンター相談）", "◇チーム懇親会費用負担", "◇技術研修・自己啓発研修等キャリアに必要な研修は申請で受講可能", "◇フリーワーク制度（社内副業）",
                "◇入社に伴う引っ越しに関しての支援金 (１都３県以外からの引っ越しに限る)"]}
              url="https://forms.gle/XeWKThHMq2r43BBj6"
            ></AccordionRequirementsNewGraduates>
          </Box>

          <Box
            sx={{
              backgroundColor: "white",
              marginTop: "5%",
              marginRight: "15%",
              borderTopRightRadius: "10px",
              borderBottomRightRadius: "10px",
            }}
          >
            <ThemeProvider theme={colorTheme}>
              <Box
                sx={{
                  padding: {xs: "10% 5%", sm: "10% 5%", md: "5%"},
                  width: { xs: "80%", sm: "80%", md: "75%", lg: "65%", xl: "45%" },
                  margin: "0 auto",
                }}
              >
                <Box
                  sx={{ fontSize:{ xs: "3.5vw", sm: "3.5vw", md: "3vw", lg:"1.5vw", xl: "1.5vw" }, padding: { xs: "3% 2%", lg: "2% 0" } }}
                  style={{
                    padding: "3% 2%",
                    borderWidth: "1",
                    border: `0.4vw solid ${colorTheme.palette.turquoiseBlue.main}`,
                    backgroundColor: "white",
                    color: `${colorTheme.palette.turquoiseBlue.main}`,
                    width: "50%",
                    textAlign: "",
                    whiteSpace: "pre-warp",
                  }}
                >
                  新卒採用
                </Box>
                <Typography
                  sx={{fontSize:{ xs: "5vw", sm: "5vw", md: "3vw", lg:"2vw", xl: "2vw" }}}
                  style={{ padding: "5% 0px 3% 0px" }}
                  fontWeight={"bold"}
                  textAlign={"left"}
                >
                  26卒向け選考直結型イベント<br />
                  <br />
                  <Typography sx={{ fontSize:{ xs: "3.2vw", sm: "3.2vw", md: "3vw", lg:"1.2vw", xl: "1vw" }, textAlign: 'left'  }}>
                    <div style={{ fontWeight:"bold" }}>＜26卒早期選考で、【プログラミング体験】にご招待！＞</div>
                    コラボテクノの若手エンジニアと一緒にプログラミング体験をしよう！<br />
                    <br />
                    ゲーム「テトリス」作りを体験しよう<br />
                    ・実施日程：<br />
                    　・2024年10月19日（土）、26日（土）<br />
                    　・2024年11月9日（土）、23日（土）<br />
                    　・2024年12月7日（土）<br />
                    　・2025年1月18日（土）<br />
                    ・実施時間：<br />
                    　13:00～16:30<br />
                    　13:00～15:30　プログラミング体験<br />
                    　15:30～16:30　コラボテクノの会社説明およびエンジニアから現場説明<br />
                    ・場所：東京都港区新橋3-11-8 オーイズミ新橋第2ビル 901号<br />
                    ・募集人員：最大4名／1回<br />
                    ・持ち物：ご自身のPCを持参ください<br />
                    ・服装：自由
                  </Typography>
                </Typography>
                <div>
                  <Box sx={{ marginTop: "10%" }}>
                    <EntryBotton text={"申し込む"} url="https://forms.gle/aYs9yXQ7kgH88G516"></EntryBotton>
                  </Box>
                </div>
              </Box>
            </ThemeProvider>
          </Box>

        <Box sx={{ paddingBottom: "10%" }}>

          <Typography id="mid-career" sx={{fontSize:{ xs: "20px", sm: "30px", md: "30px", lg:"30px", xl: "2vw" } ,textAlign: 'left',px:{xs:3,sm:1,md:1,xl:2},padding:"5% 0 0 0",color:`${colorTheme.palette.navy.main}` }} fontWeight={'bold'}>
            中途採用
          </Typography>
          <Box
            sx={{
              backgroundColor: "white",
              marginTop: "5%",
              marginRight: "15%",
              borderTopRightRadius: "10px",
              borderBottomRightRadius: "10px",
            }}
          >
            <AccordionRequirementsEntry
              category="中途採用"
              jobContent1={["経験・スキルに応じてプロジェクトに配属", "プロジェクト配属における大原則", "１．Webシステム開発のプロジェクト", "２．Javaをアプリケーション言語にしたプロジェクト", "３．目標は、プロジェクトリーダー・マネージャー"]}
              jobContent2={["【開発プロジェクトの実績】", "■通信会社向け　顧客対応システム開発（Java）", "→アジャイル", "■農業事業者向け　クラウドサービス導入支援(PHP) 　", "→顧客との要件定義づめから保守・運用まで ",
                "■損保会社向け　共通基盤開発 （Java）　", "→Javaを用いた設計から開発標準の策定を行う上流案件", " ■コミック本ECサイト構築支援(PHP)", "→バックエンドの決裁部分の構築","配属プロジェクトは入社時期によって異なります。","詳細は面接にてお問合せください。"]}
              jobContent3={[]}
              Menutext="システムエンジニア（Web・オープン系）"
              Menudetail={[]}
              condition={["■なんらかのオープン言語での開発2年以上", "■自分で課題設定ができる方", "■課題に対し工夫して取り組める方", "■信頼関係を築くのが上手な方", "■素直に物事を捉えられる方", "■PM,PL思考の方"]}
              recruitingCount="事業拡大のため、2-5名位まで/年"
              workPlace="東京都港区新橋3-11-8 オーイズミ新橋第2ビル9階(自社内で開発を行う場合と、顧客先で開発を行う場合があります)"
              workTime={["完全土日祝休み 9:30 ～ 18:30"]}
              salary={["年俸　5,000,000円　～　8,500,000円(入社当初のスキルによって変動あり)"]}
              holiday={["◇ 年間休日120日以上", "◇ 夏季休暇", "◇ 年末年始休暇", "◇ 産前産後休暇・育児休暇制度"]}
              benefit={["◇雇用保険", "◇ 厚生年金", "◇ 労災保険", "◇ 健康保険", "◇ 交通費支給あり", "◇ テレワーク・在宅OK", "◇ 時短勤務制度あり",
                "◇資格取得支援（受験料・書籍購入・メンター相談）", "◇チーム懇親会費用負担", "◇技術研修・自己啓発研修等キャリアに必要な研修は申請で受講可能", "◇フリーワーク制度（社内副業）"]}
              url="https://forms.gle/nWpW7iaq6XpuzPfR9"
            ></AccordionRequirementsEntry>
          </Box>
          <Box
            sx={{
              backgroundColor: "white",
              marginTop: "5%",
              marginRight: "15%",
              borderTopRightRadius: "10px",
              borderBottomRightRadius: "10px",
            }}
          >
            <AccordionRequirementsEntry
              category="中途採用"
              jobContent1={["経験・スキルに応じてプロジェクトに配属", "プロジェクト配属における大原則", "１．Webシステム開発のプロジェクト", "２．Javaをアプリケーション言語にしたプロジェクト", "３．目標は、プロジェクトリーダー・マネージャー"]}
              jobContent2={["【開発プロジェクトの実績】", "■通信会社向け　顧客対応システム開発（Java）", "→アジャイル", "■農業事業者向け　クラウドサービス導入支援(PHP) 　", "→顧客との要件定義づめから保守・運用まで ",
                "■損保会社向け　共通基盤開発 （Java）　", "→Javaを用いた設計から開発標準の策定を行う上流案件", " ■コミック本ECサイト構築支援(PHP)", "→バックエンドの決裁部分の構築","配属プロジェクトは入社時期によって異なります。","詳細は面接にてお問合せください。"]}
              jobContent3={[]}
              Menutext="経験浅　システムエンジニア（Web・オープン系）"
              Menudetail={[]}
              condition={["■何かしらのオープン言語半年〜2年未満", "■自分で課題設定ができる方", "■課題に対し工夫して取り組める方", "■信頼関係を築くのが上手な方", "■素直に物事を捉えられる方"]}
              recruitingCount="2-5名位まで/年"
              workPlace="東京都港区新橋3-11-8 オーイズミ新橋第2ビル9階(自社内で開発を行う場合と、顧客先で開発を行う場合があります)"
              workTime={["完全土日祝休み 9:30 ～ 18:30"]}
              salary={["年俸　3,000,000円　～　4,000,000円"]}
              holiday={["◇ 年間休日120日以上", "◇ 夏季休暇", "◇ 年末年始休暇", "◇ 産前産後休暇・育児休暇制度"]}
              benefit={["◇雇用保険", "◇ 厚生年金", "◇ 労災保険", "◇ 健康保険", "◇ 交通費支給あり", "◇ テレワーク・在宅OK", "◇ 時短勤務制度あり",
                "◇資格取得支援（受験料・書籍購入・メンター相談）", "◇チーム懇親会費用負担", "◇技術研修・自己啓発研修等キャリアに必要な研修は申請で受講可能", "◇フリーワーク制度（社内副業）"]}
              url="https://forms.gle/nWpW7iaq6XpuzPfR9"
            ></AccordionRequirementsEntry>
          </Box>
          <Box
            sx={{
              backgroundColor: "white",
              marginTop: "5%",
              marginRight: "15%",
              borderTopRightRadius: "10px",
              borderBottomRightRadius: "10px",
            }}
          >
            <AccordionRequirementsEntry
              category="中途採用"
              jobContent1={["【開発プロジェクトの実績】","■通信会社向け店頭顧客対応の開発", "■農業事業者向けクラウドサービス導入支援", "■荷物追跡サービス開発", "■アパレル会社向けEコマース保守開発","■AWSの要件定義、設計、開発","配属プロジェクトは入社時期によって異なります。","詳細は面接にてお問合せください。"]}
              jobContent2={[]}
              jobContent3={[]}
              Menutext="インフラエンジニア"
              Menudetail={[]}
              condition={["■何かしらのクラウド基盤・インフラ構築支援の経験2年以上", "■自分で課題設定ができる方", "■課題に対し工夫して取り組める方", "■信頼関係を築くのが上手な方", "■素直に物事を捉えられる方"]}
              recruitingCount="2-3名"
              workPlace="東京都港区新橋3-11-8 オーイズミ新橋第2ビル9階(自社内で開発を行う場合と、顧客先で開発を行う場合があります)"
              workTime={["完全土日祝休み 9:30 ～ 18:30"]}
              salary={["年俸　 5,000,000円　～　8,000,000円"]}
              holiday={["◇ 年間休日120日以上", "◇ 夏季休暇", "◇ 年末年始休暇", "◇ 産前産後休暇・育児休暇制度"]}
              benefit={["◇雇用保険", "◇ 厚生年金", "◇ 労災保険", "◇ 健康保険", "◇ 交通費支給あり", "◇ テレワーク・在宅OK", "◇ 時短勤務制度あり",
                "◇資格取得支援（受験料・書籍購入・メンター相談）", "◇チーム懇親会費用負担", "◇技術研修・自己啓発研修等キャリアに必要な研修は申請で受講可能", "◇フリーワーク制度（社内副業）"]}
              url="https://forms.gle/nWpW7iaq6XpuzPfR9"
            ></AccordionRequirementsEntry>
          </Box>

          <Box
            sx={{
              backgroundColor: "white",
              marginTop: "5%",
              marginRight: "15%",
              borderTopRightRadius: "10px",
              borderBottomRightRadius: "10px",
            }}
          >
            <AccordionRequirementsEntry
              category="中途採用"
              jobContent1={["【募集背景】",
                "当社「コラボテクノ」では、現在、大手のSierに対し、",
                "WEB開発、クラウド基盤構築を中心にしたSES事業を中心に行っていますが、",
                "今後これに加え、企業のIT化支援（DX推進やITコンサルティング）を展開したいと考えおり、",
                "今後の事業の仲間になっていただける方を積極的に募集します。"
              ]}
              jobContent2={["【業務概要】",
                            "・PJでアーキテクチャ、設計、見積りを行いメンバーリードする",
                            "・品質管理",
                            "・既存・新規クライアントとコミュニケーションを図り深耕を行う",
                            "・エンジニアの育成、能力・特性に応じた若手エンジニアの教育"
                          ]}
              jobContent3={[
                            "【業務詳細】", 
                            "■顧客・新規クライアントの深耕",
                            "売上げ拡大のために、既存クライアントに入り込み深耕を行う",
                             "新規クライアントにアプローチし関係を構築する","　",
                            "■品質管理",
                            "メンバーが作成した開発成果物に対してレビューを⾏い、内容が妥当であるか確認する",
                            "※開発成果物として下記などを想定する",
                            "・UI ∕ UX含めた動作",
                            "・設計書",
                            "・コード",
                            " ・テスト仕様書、および、実⾏結果"
                            ,"妥当でない場合は対話などで状況を確認、対応が必要な場合は原因を追究し改善する","　",
                            "■進捗管理",
                            "・進捗の予実管理",
                            "　進捗の予実管理を⾏う",
                            "　予実に差異が発⽣した際はその原因を追求し改善する",
                            "・進捗の妥当性確認","　進捗が妥当であるか確認を⾏う",
                            "　妥当でない場合は対話などで状況を確認し、対応が必要な場合は原因を追究し改善する","　",
                            "■生産性向上",
                            "・プロセス改善",
                            "　業務を行う上でボトルネックとなっている箇所を把握し改善する",
                            "・開発環境の整備","　エンジニアが開発する上でボトルネックとなっている個所を把握し改善する","　",
                            "■エンジニアリング力向上",
                            "・育成",
                            "　⽬標設定や1on1を通じ、エンジニアの市場価値の向上を導く",
                            "・教育","　エンジニアの目標/スキルに応じて、教育・研修を推進する","　",
                            "■情報発信",
                            "　記事執筆や登壇などの情報発信を行い、コラボテクノのプレゼンスを向上する","　",
                            "【仕事の魅力と希少性】","・ Techサイドから大きな裁量をもってリードできる",
                            "・ 第二創業期の成長フェーズのため、開発をリードする中核メンバーに喰いこみやすいフェーズ"
                          ]}
              Menutext="プロジェクトマネージャー"
              Menudetail={[]}
              condition={[
                          "【必須スキル】",
                          "・なんらかのオープン言語での開発5年以上",
                          "・課題抽出を適切に行い、その解決策を正しく策定する力",
                          "・課題や対応内容を適切に言語化し、関係者に分かりやすく説明する力",
                          "・年間25人月程度のプロジェクトマネジメント経験",
                          "・エンジニア3人以上のヒューマンマネジメント経験",
                          "・Webサービス開発における、要件定義から運用保守までの一連の経験",
                          "・アンラーニング",
                          "（足りないスキルセットを自ら学習し補う力、自身の経験やスキルセットをそれに適応させる力）",
                          "・コラボテクノのMission/Vision/Valuesへの共感いただける方","　",
                          "【歓迎スキル】",
                          "・開発プロセスのロードマップ・生産性向上・エンジニアリング力向上などの領域をリードし、ビジネスの価値向上/働きやすい環境の整備などを行ってきたご経験","　",
                          "【求める人物像】","・主体的にものごとを進められる方","・整備されていない環境を楽しめる方"
                        ]}
              recruitingCount="2-3名"
              workPlace="東京都港区新橋3-11-8 オーイズミ新橋第2ビル9階(自社内で開発を行う場合と、顧客先で開発を行う場合があります)"
              workTime={["完全土日祝休み 9:30 ～ 18:30"]}
              salary={["年俸　 7,200,000円　～　10,000,000円※ご経験により異なります。"]}
              holiday={["◇ 年間休日120日以上", "◇ 夏季休暇", "◇ 年末年始休暇", "◇ 産前産後休暇・育児休暇制度"]}
              benefit={[
                "◇雇用保険",
                "◇ 厚生年金",
                "◇ 労災保険", 
                "◇ 健康保険", 
                "◇ 交通費支給あり", 
                "◇ テレワーク・在宅OK", 
                "◇ 時短勤務制度あり",
                "◇資格取得支援（受験料・書籍購入・メンター相談）", 
                "◇チーム懇親会費用負担", 
                "◇技術研修・自己啓発研修等キャリアに必要な研修は申請で受講可能", 
                "◇フリーワーク制度（社内副業）"]}
              url="https://forms.gle/nWpW7iaq6XpuzPfR9"
            ></AccordionRequirementsEntry>
          </Box>

          <Box
            sx={{
              backgroundColor: "white",
              marginTop: "5%",
              marginRight: "15%",
              borderTopRightRadius: "10px",
              borderBottomRightRadius: "10px",
            }}
          >
            <AccordionRequirementsEntry
              category="中途採用"
              jobContent1={["【仕事内容】",
                "当社「コラボテクノ」では、今まで大手Sierに対しSES事業を中心に行ってきましたが、",
                "今後、直接、企業のIT支援（DX推進やITコンサルティング）の強化を考えています。",
                "今回は、この事業領域の拡大に伴い、",
                "営業経験を活かして当社の成長をサポートしてくださる営業担当者を募集します。",
                "具体的には以下の業務を担当いただきます。",
                "",
                "・新規顧客開拓および既存顧客との関係強化",
                "・受託開発およびSESビジネスの提案営業",
                "・DXやITコンサルティングに関連するプロジェクトの提案、契約交渉、進捗管理",
                "・エンジニアチームとの連携、提案内容に基づいた技術面の調整"
              ]}
              jobContent2={[]}
              jobContent3={[]}
              Menutext="営業"
              Menudetail={[]}
              condition={[
                          "【求めるスキル・経験】",
                          "・IT業界での法人営業経験/無形商材の法人営業経験 5年以上",
                          "■新規開拓や提案型営業の実績",
                          "■DXやITソリューションに対する知識",
                          "　",
                          "【歓迎スキル】",
                          "受託開発やSES営業の経験がある方歓迎",
                          "SIerやSES事業に関連する顧客基盤をお持ちの方",
                          "チームリーダーシップ、プロジェクトマネジメント経験",
                          "当社のレクリエーションイベント等にも積極的に参加いただける方"
                        ]}
              recruitingCount="1名"
              workPlace="東京都港区新橋3-11-8 オーイズミ新橋第2ビル9階"
              workTime={[
                        "完全土日休み",
                        "9:30 ～ 18:30",
                        "休憩時間：12：00〜13：00（60分）",
                        "平均残業時間：平均10時間／月 ※基本残業は多く発生しません。"
                    ]}
              salary={[
                       "年収 500万～800万円（経験・スキルに応じて決定）",
                       "月給は、12分割で支給、見なし固定残業45時間分含む",
                       "　",
                       "【給与詳細】",
                       "・45時間超過分は別途支給",
                       "・別途業績に応じた賞与を支給（年1回）",
                       "・ミッショングレード制をテスト施行中",
                       "（年功序列ではなく、スキルレベルに応じた給料体系となっております。",
                       "・昇給は年１回です。",
                       "（人事考課制度1回、四半期ごとの振り返りでの目標管理をしております。）"
                      ]}
              holiday={["◇ 年間休日120日以上", "◇ 夏季休暇", "◇ 年末年始休暇", "◇ 産前産後休暇・育児休暇制度"]}
              benefit={[
                "◇雇用保険",
                "◇ 厚生年金",
                "◇ 労災保険", 
                "◇ 健康保険", 
                "◇ 交通費支給あり", 
                "◇ テレワーク・在宅OK", 
                "◇ 時短勤務制度あり",
                "◇資格取得支援（受験料・書籍購入・メンター相談）", 
                "◇チーム懇親会費用負担", 
                "◇技術研修・自己啓発研修等キャリアに必要な研修は申請で受講可能", 
                "◇フリーワーク制度（社内副業）"]}
              url="https://forms.gle/nWpW7iaq6XpuzPfR9"
            ></AccordionRequirementsEntry>
          </Box>

          <Box
            sx={{
              backgroundColor: "white",
              marginTop: "5%",
              marginRight: "15%",
              borderTopRightRadius: "10px",
              borderBottomRightRadius: "10px",
            }}
          >
            {/* <AccordionRequirementsEntry
              category="ライブ配信"
              Menutext="医療系ライブ配信ディレクター"
              Menudetail="外資・内資の大手製薬メーカーを中心に医師向け講演会などのインターネットライブ中継のディレクション業務をお任せします。"
              period="冬季インターンシップ（12月～2月）"
              occupation="システムエンジニア"
              content="IT業界のいろは"
              condition="将来Web業界（ディレクター、プログラマー、システムエンジニア等）で働きたい強い希望があること"
              belongings={[
                "筆記用具",
                "愛用のノートパソコンがあるならば持参OK",
              ]}
              selection="応募順に選考いたします。定員に達し次第、締切ります。"
              url="https://forms.gle/D1G9KdhM9feAVZJe7"
            ></AccordionRequirementsEntry>
          </Box>
          <Box
            sx={{
              backgroundColor: "white",
              marginTop: "10%",
              marginRight: "15%",
              borderTopRightRadius: "10px",
              borderBottomRightRadius: "10px",
            }}
          >
            <AccordionRequirementsEntry
              category="その他"
              Menutext="インターシップ"
              Menudetail="冬季(12～2月)インターシップの募集です。IT業界の知識やシステムエンジニアリングの業務を学べます。"
              period="冬季インターンシップ（12月～2月）"
              occupation="システムエンジニア"
              content="IT業界のいろは"
              condition="将来Web業界（ディレクター、プログラマー、システムエンジニア等）で働きたい強い希望があること"
              belongings={[
                "筆記用具",
                "愛用のノートパソコンがあるならば持参OK",
              ]}
              selection="応募順に選考いたします。定員に達し次第、締切ります。"
              url="https://forms.gle/Sh1pEQ1GFUK3mU8QA"
            ></AccordionRequirementsEntry> */}
          </Box>
        </Box>
      </Box>
      <ScrollToTopIcon />
    </Box>
  );
}
