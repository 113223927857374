import React from "react";
import GoogleMapReact from 'google-map-react';

export default function GoogleMap(){
  const defaultProps = {
    center: {
      lat: 35.6661735,
      lng: 139.75572450000004
    },
    zoom: 17
  };
  var icon = `${process.env.PUBLIC_URL}/image/company/icon.png`;

  const handleApiLoaded = ({ map, maps }) => {
    new maps.Marker({
      map,
      position: defaultProps.center,
      icon : icon,
    });
  };

  return (
    <div style={{ height: '450px', width: '100%' }}>
      <GoogleMapReact
        bootstrapURLKeys={{ key: process.env.REACT_APP_GOOGLE_MAP_KEY }}
        defaultCenter={defaultProps.center}
        defaultZoom={defaultProps.zoom}
        onGoogleApiLoaded={handleApiLoaded}
      >
      </GoogleMapReact>
    </div>
  );
}