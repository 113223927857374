import * as React from 'react';
import MuiButton from '@mui/material/Button';
import { ThemeProvider } from '@mui/material/styles';
import { colorTheme } from '../const';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import {HashLink} from 'react-router-hash-link';

export default function ButtonCircle(props) {
  return (
    <ThemeProvider theme={colorTheme}>
      <HashLink to={props.url}>
        <MuiButton color='blackGray' sx={{
          backgroundColor: props.color,
          border: 'solid 2px',
          borderRadius: '9999px',
          fontSize: '15px',
          letterSpacing: '0.1em',
          width: '75%',
          padding: props.padding ? props.padding : '10px 3vw 10px 6vw',
          justifyContent: 'space-between'
        }}>
          <b>{props.text}</b>
          <ArrowForwardIosIcon sx={{ height: '15px' }} />
        </MuiButton>
      </HashLink>
    </ThemeProvider>
  );
}
