import { Box, Typography, ThemeProvider, autocompleteClasses } from "@mui/material";
import * as React from "react";
import { colorTheme } from "../const";
import EmployeesTopImg from "../image/web-photo-02.png";
import LeaderImg from "../image/IMG_0846.jpg";
import ContentsBoxWithTag from "../components/ContentsBoxWithTag";
import ContentsBoxWithBottomAndImage from "../components/ContentsBoxWithBottomAndImage";
import Slider from "../components/Slider";
import Tag from "../components/Tag";
import ScrollToTopIcon from './../components/ScrollToTopIcon';

export default function Employees(props) {
  React.useEffect(() => {
    if (props.pageTitle !== undefined && props.pageTitle !== null) {
      document.title = props.pageTitle + `｜コラボテクノ株式会社`;
    }
  });
  return (
    <Box className="App">
      <Box sx={{ backgroundColor: `${colorTheme.palette.creamGray.main}` }}>
        <Box sx={{ display: { xs: "none", sm: "block", md: "block" } }}>
          <ThemeProvider theme={colorTheme}>
            <div style={{ position: 'relative', width: '100%', padding: '0px 10px 45% 0px' }}>
              <img src={process.env.PUBLIC_URL + EmployeesTopImg} width='70%' height='100%' alt='トップ画像' style={{ position: 'absolute', right: 0 }}></img>
              <div style={{
                display: 'inline-block',
                width: '35%',
                minWidth: '310px',
                height: '85%',
                backgroundColor: 'white',
                position: 'absolute',
                left: 0,
                top: 30,
                borderRadius: '0px 20px 20px 0px',
                whiteSpace: 'pre-line',
              }}>
                <br />
                <Tag tagStyle={'left'} tagText={"INTERVIEW"} />
                <br />
                <Box style={{
                  padding: '20px 5px 0px 40px',
                  textAlign: 'left'
                }}>
                  <Typography variant="h5" sx={{ padding: '0 0 2% 0', textAlign: 'left', fontWeight: 'bold', fontSize: '2.3vw', color: colorTheme.palette.navy.main }}>
                    {"\n新卒から\nリーダーまで \n社員インタビュー"}
                  </Typography>
                  <Typography variant="h9" sx={{ padding: '40px 0px', textAlign: 'left', fontSize: '1vw', lineHeight: '200%' }}>
                    {'コラボテクノで働くエンジニアたちに、\n会社や仕事、就職のことをインタビュー。\nまた社内のチームリーダー同士の\n対談も掲載しています。'}
                  </Typography>
                </Box>
              </div>
            </div>
          </ThemeProvider>
        </Box>
        <Box sx={{ display: { xs: "block", sm: "none", md: "none", xl: "none" } }}>
          <img src={process.env.PUBLIC_URL + EmployeesTopImg} style={{ width: '100%' }} alt='社員のことTop画像'></img>
        </Box>
      </Box>
      <Box sx={{ display: { xs: "block", sm: "none" } , padding:"5% 0 5% 0", color: `${colorTheme.palette.navy.main}`,backgroundColor:`${colorTheme.palette.creamGray.main}` }}>
        <ContentsBoxWithTag
          tagStyle={{ float: "left" }}
          tagText={"INTERVIEW"}
          title={"新卒からリーダーまで \n社員インタビュー"}
          text={
            "コラボテクノで働くエンジニアたちに、\n会社や仕事、就職のことをインタビュー。\nまた社内のチームリーダー同氏の対談も掲載しています。"
          }
        ></ContentsBoxWithTag>
      </Box>
      <Box sx={{ background: "white", paddingTop: "50px", paddingBottom: "50px"}}>
        <Box sx={{ background: "white", marginBottom: "50px" }}>
          <div style={{ padding: '0 15%', margin: '0 15px' }}>
            <Typography
              textAlign={{ xs: "left", sm: "center", md: "center" }}
              sx={{
                paddingBottom: "20px",
                color: colorTheme.palette.navy.main,
                fontSize: "16px",
                fontWeight: "bold"
              }}
            >
              新卒入社
            </Typography>
          </div>
          <Slider category="newEmployee" slidesToShow={3}></Slider>
        </Box>
        <Box sx={{ background: "white", marginBottom: "50px" }}>
          <div style={{ padding: '0 15%', margin: '0 15px' }}>
            <Typography
              textAlign={{ xs: "left", sm: "center", md: "center" }}
              sx={{
                paddingBottom: "20px",
                color: colorTheme.palette.navy.main,
                fontSize: "16px",
                fontWeight: "bold",
              }}
            >
              異業種転職
            </Typography>
            </div>
          <Slider category="jobChange" slidesToShow={2}></Slider>
        </Box>
        <Box sx={{ background: "white" }}>
          <div style={{ padding: '0 15%', margin: '0 15px' }}>
            <Typography
              textAlign={{ xs: "left", sm: "center", md: "center" }}
              sx={{
                paddingBottom: "20px",
                color: colorTheme.palette.navy.main,
                fontSize: "16px",
                fontWeight: "bold",
              }}
            >
              ライフワークバランス
            </Typography>
            </div>
          <Slider category="lifeWorkValance" slidesToShow={2}></Slider>
        </Box>
      </Box>
      <Box
        sx={{
          backgroundColor: `${colorTheme.palette.creamGray.main}`,
          padding: "5% 0 5% 0%"
        }}
      >
        <Box sx={{ position: "absolute", marginTop: "2%" }}>
          <Tag tagStyle={"left"} tagText={"LEADER TALK"}></Tag>
        </Box>
        <Box
          sx={{
            background: { xs: "white", sm: `${colorTheme.palette.creamGray.main}`, md: `${colorTheme.palette.creamGray.main}` },
            margin: "0 5% 0 5%",
            padding: "0 4% 0% 4%",
            borderRadius: "3%",
            width: autocompleteClasses
          }}
        >
          <ContentsBoxWithBottomAndImage
            url={"/recruit/member/discussion"}
            imageSrc={LeaderImg}
            tagText={"LEADER TALK"}
            buttonText={"続きを読む"}
            title={"コラボテクノは、\n新人が働きやすい\n会社です。\n\nチームリーダー対談"}
            text={
              "\n社内チームリーダーの3名が、会社や現場業務の事から新人の育成まで、フランクに語り合いました。"
            }
          ></ContentsBoxWithBottomAndImage>
        </Box>
      </Box>
      <ScrollToTopIcon />
    </Box>
  );
}
