import img2 from '../image/IMG_9786.png';
import img3 from '../image/IMG_0125.jpg';
import img4 from '../image/IMG_0182.jpg';
import img5 from '../image/IMG_9720.jpg';
import img6 from '../image/IMG_0311.jpg';
import img7 from '../image/IMG_0369.jpg';
import img8 from '../image/IMG_0513.jpg';
import img9 from '../image/IMG_0656.jpg';
import img10 from '../image/IMG_0706.jpg';
import img11 from '../image/IMG_0819.jpg';
import img12 from '../image/IMG_9973.jpg';
import img13 from '../image/IMG_0935.jpg';
import img14 from '../image/IMG_2058.jpg';
import img15 from '../image/IMG_9318.jpg';
import img16 from '../image/IMG_9365.jpg';
import img17 from '../image/IMG_9445.jpg';
import img18 from '../image/IMG_9808.jpg';
import img19 from '../image/IMG_9631.jpg';
import img20 from '../image/IMG_0274.jpg';

const recruitTopSliderImages = [
    img2, img3, img4, img5, img6, img7, img8, img9, img10,
    img11, img12, img13, img14, img15, img16, img17, img18, img19, img20,
];
export default recruitTopSliderImages;
