import { autocompleteClasses, Box, Typography ,Grid} from '@mui/material';
import * as React from 'react';
import { ThemeProvider } from '@mui/material/styles';
import { colorTheme } from '../const';
import Tag from './Tag';

export default function ContentsBoxWithTag(children) {
  return (
    <ThemeProvider theme={colorTheme}>
      <Box sx={{
        width: autocompleteClasses,
        height: autocompleteClasses,
        backgroundColor: 'inherit',
        position: 'relative',
        whiteSpace: 'pre-line',
      }}>
        <Tag tagStyle={children.tagStyle} tagText={children.tagText} />
        <Grid container Spacing={1} >
          <Grid item xs={12} sm={12} md={12} xl={12}>
          <Typography  sx={{fontSize:{ xs: "20px", sm: "30px", md: "30px", lg:"30px", xl: "2vw" } ,textAlign: 'left',px:{xs:3,sm:1,md:1,xl:2},padding:"5% 0 0 0",color:`${colorTheme.palette.navy.main}` }} fontWeight={'bold'}>
            {children.title}
          </Typography>
          </Grid>
          <Grid item xs={12} sm={12} md={12} xl={12}>
                    <Typography sx={{fontSize:{ xs: "12px", sm: "15px", md: "20px",lg:"25px", xl: "1.2vw" }, textAlign: 'left', px:{xs:3,sm:1,md:1,xl:2} ,padding:"5% 0 5% 0" }}>
            {children.text}
          </Typography>
          </Grid>
          </Grid>
      </Box>
    </ThemeProvider>
  )
}
