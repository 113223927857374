import * as React from 'react';
import '../../../App.css';
import PersonInterview from '../../../components/PersonInterview';
import JobChange1Top from '../../../image/interviews/job_change1_top.png'
import JobChange1Text1 from '../../../image/interviews/job_change1_text1.png'
import JobChange1Text2 from '../../../image/interviews/job_change1_text2.png'
import JobChange1Text3 from '../../../image/interviews/job_change1_text3.png'
import { useMediaQuery } from 'react-responsive';

export default function JobChangeInterview1(props) {
  React.useEffect(() => {
    if (props.pageTitle !== undefined && props.pageTitle !== null) {
      document.title = props.pageTitle + `｜コラボテクノ株式会社`;
    }
  });
  const isMobileScreen = useMediaQuery({ query: '(max-width: 760px)' })
  return (
    <PersonInterview
      nextUrl={"/recruit/member/job-change-interview2"}
      tagStyle={{ float: 'left' }}
      tagText={"INTERVIEW"}
      name={"齋藤 裕太"}
      entryYear={"2019"}
      title={isMobileScreen ? "知人の後押しで、書店員から\nプログラミングの世界へ。" : "知人の後押しで、\n書店員から\nプログラミングの\n世界へ。"}
      imageSrcTop={JobChange1Top}
      text1={"理系の大学院に通っていた頃は、このまま研究者になろうと思っていました。でも、教授から割り振られた研究テーマに、どうしても興味を持てなくて。結局、途中で院を辞め、誘われるまま当時アルバイトしていた大手書店の正社員になりました。勤務先は大きな書店だけに書籍数も客数も多く、接客や陳列、在庫管理で、一日中立ちっぱなし。やりがいはあるけれど、体力的にしんどい仕事でした。どこかに遊びに行く気力もなく、家に帰って寝るだけの毎日に少し疑問が生じてきた頃、趣味のオンラインゲームを通じて、プログラマをやっている人と知り合いました。話を聞いてプログラミングって楽しそうだなと思い、またその知人の勧めもあって、プログラミングスクールに通い始めました。\n\nやってみると知人のいう通りプログラミングは楽しく、スクールの途中から転職を真剣に検討していました。就職の世話もしてくれるスクールだったので、求人票が来ていたコラボテクノの面接を受け、とても好印象だったので入社しました。給与面も、それで決めたわけではありませんが、書店勤めの時よりも多くて、びっくりしました。"}
      imageSrcText1={JobChange1Text1}
      text2Head={"キャリア３年で、１０人規模の\nエンジニアチームを束ねるリーダー。"}
      text2Body={"プログラマとして経験を積み、キャリア3年を過ぎた今は、携帯電話会社の顧客対応システムの保守開発やアンケート管理システムを担当するエンジニアチームのリーダーをしています。メンバーは10人弱で、コラボテクノの後輩だけでなく、いろいろな会社から来たエンジニアがいて、作業の割り振りや進行管理、お客さんとのコミュニケーションなど、マネジメント業務の割合が大きいです。とにかく進捗の部分でヘルプがいいづらい状況に陥らないよう、分け隔てなくフランクに話せる環境づくりに気をつけています。また、お客さんの認識、つくるものの認識にズレがあると困るので、積極的にアプローチしてきっちり確認を取ることを心がけています。こうしたお客さんやメンバーとのコミュニケーションをうまくやれているのは、もしかしたら前職での接客の経験が生きているのかもしれませんね。\n\nちなみに1日の勤務時間は9時から17時半、1時間の昼休憩を除いて7時間半です。在宅勤務なので、折を見て適度に姿勢を変えたり、運動したり。ずっと立ちっぱなしなんてことはなく、体力はあまり消耗しませんね。書店員よりエンジニアの方がラクだなんて信じられませんか？でも本当なんですよ。これだけでも転職してよかったです。"}
      imageSrcText2={JobChange1Text2}
      text3Head={"前職になかった、将来の選択肢を\n自分で選べる、楽しさ。"}
      text3Body={"転職に関してはあらかじめ知人を質問攻めして、業界のリアルを知っていたので、ネガティブな印象を持つこともなく、普通に転職できました。こうした信頼できる知り合いがいない場合は、不安もあるかと思いますが、少しでも興味があるなら、異業種からでもどんどんチャレンジしてほしいですね。前職を続けていたら選べなかった、いろんな将来の選択肢を自分で選べる楽しさが、ここにはあります。\n\nこれからは、もう少し大きなチームをまとめて管理していけるようになりたいと思っています。一方で、個人事業主という方向も頭にあって、どっちもありかな、と。こうして悩めるって贅沢なことだなと思います。"}
      imageSrcText3={JobChange1Text3} />
  );
}